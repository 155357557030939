import React, { FunctionComponent } from "react";
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text'
import Section from './shared/section/sectionWrapper'

const InvisibleQuality: FunctionComponent = () => {
    return (
        <Section className="bg-bytestrone-blue px-4">
             <div id="invisible-quality" className="pb-4"></div>
            <div id="invisible-quality" className="flex flex-col w-full h-full p-2 py-8 space-y-5">
                <Text className="text-center text-white lg:text-right whitespace-nowrap" textType="headerL">The Invisible Quality</Text>

                <div>
                    <Text textType="contentXl" className="text-white">
                        <b>Quality</b> is the aspect that nobody wants to compromise; be it a customer or a service
                        provider. Because, quality defines the organization’s credibility. However, quality is
                        never an accident. It needs a focused and skillful craftsmanship.
                    </Text>
                </div>

                <div>
                    <Text textType="contentXl" className="text-white">
                        Every enterprise spends significant effort to achieve optimum quality. However,
                        understanding the right areas of quality and channelizing the effort is going to help
                        optimize <b>cost of quality.</b>
                    </Text>
                </div>

                <div>
                    <Text textType="contentXl" className="text-white">
                        The visible quality issues is just the tip of the iceberg and addressing them is not
                        sufficient. Knowing the invisible parts of quality is the key to optimize <b>cost of quality.</b>
                    </Text>
                </div>

                <div className="flex flex-wrap h-full md:pl-4 md:pt-8 text-white">

                    <div className="flex w-full justify-center items-center">

                        <div>
                            <StaticImage alt="invisible-quality" placeholder="blurred" quality={100}
                                src="../images/invisible-quality.png" formats={["auto", "webp", "avif"]} />
                        </div>
                    </div>
                </div>

            </div>
        </Section>
    )
}

export default InvisibleQuality;