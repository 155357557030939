import React, { FunctionComponent } from "react";
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text'
import Section from './shared/section/sectionWrapper'

const HiddenChallanges: FunctionComponent = () => {
    return (
        <Section className="bg-white px-4">
            <div id="hidden-challenges" className="pb-4"></div>
            <div id="hidden-challenges" className="flex flex-col w-full h-full p-2 py-8 space-y-5">
                <Text className="text-center text-bytestrone-blue lg:text-left" textType="headerL">
                    Hidden Challenges</Text>

                <div className="">
                    <Text textType="contentXl">
                        Many believe that making <b>things work somehow </b> and worry about making <b>it better later </b> is the easy choice to meet deadlines.
                        They prefer to take shortcuts to avoid some of the initial configuration efforts and Engineering best practices.
                        In most cases, this mindset will cost a lot to delivery in the form of quality issues, missed deadlines, and many
                        more where the <b>same team </b> will have to struggle to resolve all of them.
                    </Text>
                </div>


                <div className="flex flex-wrap h-full items-center md:pl-12 space-y-5">

                    <div className="lg:w-1/2">
                        <StaticImage alt="hidden-challenges" placeholder="blurred" quality={100}
                            src="../images/hidden-challenges.png" formats={["auto", "webp", "avif"]} />
                    </div>

                    <div className="lg:w-1/2 md:pl-8">
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 md:text-base text-sm font-bold rounded-full bg-blue-800 flex items-center justify-center text-white">1</div>
                            <span className="pl-5">Accumulating technical debt, unacceptable number of defects,
                                productivity loss, significant rework, accessibility non-compliance,
                                poor usability, unhappy customer?</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 md:text-base text-sm font-bold rounded-full bg-blue-800 flex items-center justify-center text-white">2</div>
                            <span className="pl-5">Clueless errors in higher environments, very expensive to fix prod
                                issues, unable to trace or troubleshoot end to end business
                                transactions, no visibility into user’s usage patterns?</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 md:text-base text-sm font-bold rounded-full bg-blue-800 flex items-center justify-center text-white">3</div>
                            <span className="pl-5">Takes ever to add new features, takes a long time to get a new
                                engineer productive, spending significant effort to upgrade or
                                change frameworks/tools?</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 md:text-base text-sm font-bold rounded-full bg-blue-800 flex items-center justify-center text-white">4</div>
                            <span className="pl-5">Surprise performance issues, intermittent failures, app keeps
                                crashing without graceful recovery?</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 md:text-base text-sm  font-bold rounded-full bg-blue-800 flex items-center justify-center text-white">5</div>
                            <span className="pl-5">Security issues identified too late, app vulnerable to cyber attacks,
                                challenges to meet security compliance and standards?</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 md:text-base text-sm  font-bold rounded-full bg-blue-800 flex items-center justify-center text-white">6</div>
                            <span className="pl-5">Unstable environments, expensive rework, huge defect leakage,
                                issues after every deployment and struggling with continuous
                                delivery?</span>
                        </div>
                    </div>

                </div>
                <div className="">
                    <Text textType="contentXl">
                        <b>Do you experience that some of these aspects are considered as an after-thought?</b>
                    </Text>
                </div>
            </div>            
        </Section>
    )
}

export default HiddenChallanges;