import React, { FunctionComponent } from "react";
//import SectionFullScreen from "../section/sectionFullScreen";

export interface IText {
  textType:
    | "hero"
    | "headerXl"
    | "headerL"
    | "header"
    | "contentXl"
    | "contentXXl"
    | "contentL"
    | "content"
    | "contentSm";
  className?: string;
}

const Text: FunctionComponent<IText> = (props) => {
  let component = <></>;
  switch (props.textType) {
    case "hero":
      component = (
        <h1
          className={`${props.className} font-bytestrone text-4xl md:text-6xl lg:text-7xl font-bold`}
        >
          {props.children}
        </h1>
      );
      break;
    case "headerXl":
      component = (
        <h1
          className={`${props.className} font-bytestrone text-4xl md:text-6xl lg:text-8xl font-bold`}
        >
          {props.children}
        </h1>
      );
      break;
    case "headerL":
      component = (
        <h2
          className={`${props.className} font-bytestrone text-xl md:text-4xl lg:text-6xl xl:text-7xl font-bold`}
        >
          {props.children}
        </h2>
      );
      break;
    case "header":
      component = (
        <h3
          className={`${props.className} font-bytestrone text-xl md:text-5xl lg:text-5xl font-bold`}
        >
          {props.children}
        </h3>
      );
      break;
    case "contentL":
      component = (
        <h6
          className={`${props.className} font-bytestrone text-base md:text-lg lg:text-xl`}
        >
          {props.children}
        </h6>
      );
      break;
    case "contentXl":
      component = (
        <span
          className={`${props.className} font-bytestrone text-sm md:text-base lg:text-xl xl:text-2xl`}
        >
          {props.children}
        </span>
      );
      break;
    case "contentXXl":
      component = (
        <span
          className={`${props.className} font-bytestrone text-sm md:text-xl lg:text-2xl xl:text-3xl`}
        >
          {props.children}
        </span>
      );
      break;
    case "content":
      component = (
        <span
          className={`${props.className} font-bytestrone text-xs md:text-base lg:text-base`}
        >
          {props.children}
        </span>
      );
      break;
    case "contentSm":
      component = (
        <span
          className={`${props.className} font-bytestrone text-sm md:text-base lg:text-base`}
        >
          {props.children}
        </span>
      );
      break;
  }

  return component;
};

export default Text;
