import React, { FunctionComponent } from "react";
import { StaticImage } from 'gatsby-plugin-image'
import Section from './shared/section/sectionWrapper'
import Text from './shared/text'

const EngineeringPuzzle: FunctionComponent = () => {


    return (
        <Section className="bg-bytestrone-blue px-4">
            <div id="engineering-puzzle" className="pb-4"></div>
            <div className="flex flex-col w-full h-full p-2 py-8">
                <div>
                    <Text className="text-center text-white lg:text-right whitespace-nowrap" textType="headerL">Engineering Puzzle</Text>
                </div>
                <div className="flex flex-wrap h-full items-center  justify-center md:pl-11">

                    <div className="lg:w-1/2">
                        <div className="lg:px-2 pt-8 lg:w-4/5">
                            <Text textType="contentXl" className="text-white">
                                <p className="">
                                    &quot;Software Engineering&quot; is the <strong>most complex puzzle</strong> in Software Delivery.
                                    Many organizations fail to understand
                                    the different pieces of the puzzle and in particular the complexity involved. Modern product development need
                                    a <strong>holistic approach</strong> to Software Engineering than just focusing on Architecture, Design, Coding And Testing.
                                    Understanding the different pieces of the puzzle and having the capability to <strong>unlock the complexity</strong> is a key
                                    differentiator for success.
                                </p>
                            </Text>
                        </div>
                    </div>

                    <div className="lg:w-1/2 p-8">
                        {/* <div className="lg:hidden">
                            <Text className="text-center text-white lg:text-right whitespace-nowrap" textType="headerL">Engineering Puzzle</Text>
                        </div> */}
                        <div className="xl:w-3/4">
                            <StaticImage alt="engineering puzzle" placeholder="blurred" quality={100}
                            src="../images/software-engineering-puzzle.png" formats={["auto", "webp", "avif"]} />
                        </div>
                    </div>


                </div>
            </div>            
        </Section>
    )
}

export default EngineeringPuzzle;