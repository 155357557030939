import React, { FunctionComponent } from 'react'

const SectionWrapper:FunctionComponent<{className?:String}> = ({className,children})=> {
    return (
        <section className={`${className} p-1 md:py-10 md:pl-20 min-h-screen  animate-scroll`} data-animate-type="motion-safe:animate-fadeIn">
            {children}
        </section>
    )
}


export default SectionWrapper;
