import React, { FunctionComponent } from "react";
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text'
import Section from './shared/section/sectionWrapper'

const TeamDynamics: FunctionComponent = () => {
    return (
        <Section className="bg-white px-4">
            <div id="team-dynamics" className="pb-4"></div>
            <div id="team-dynamics" className="flex flex-col w-full h-full p-2 py-8 space-y-5">
                <Text className="text-center lg:text-left text-bytestrone-blue whitespace-nowrap" textType="headerL">Team Dynamics</Text>

                <div>
                    <Text textType="contentXl">
                        <div className="lg:pl-16 lg:pr-16 p-4 bg-bytestrone-grey rounded-lg">
                            <p className="mx-4 lg:text-xl lg:text-left sm:text-center">
                                &quot; Coming together is a beginning. Keeping together is progress. Working
                                together is success&quot; - Henry Ford.
                            </p>
                        </div>

                    </Text>
                </div>

                <div>
                    <Text textType="contentXl">
                        Having the right team makes the difference between success and failure.
                    </Text>
                </div>

                <div>
                    <Text textType="contentXl">
                        There are many psychological forces that influence team’s performance and behavior.
                        Understanding and handling these team dynamics is the most critical aspect of
                        successful delivery.
                    </Text>
                </div>

                <div className="flex flex-wrap h-full md:pl-4">

                    <div className="flex w-full justify-center items-center">
                        <div className="hidden lg:block">
                            <StaticImage alt="team-dynamics" placeholder="blurred" quality={100}
                                src="../images/team-dynamics.png" formats={["auto", "webp", "avif"]} />
                        </div>

                        <div className="lg:hidden">
                            <StaticImage alt="team-dynamics" placeholder="blurred" quality={100}
                                src="../images/team-dynamics-mobile.png" formats={["auto", "webp", "avif"]} />
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default TeamDynamics;