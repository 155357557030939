import React,{FunctionComponent} from "react";
import BeyondEngineering from "../components/beyond-engineering";
import EngineeringPuzzle from "../components/engineering-puzzle";
import HiddenChallanges from "../components/hidden-challenges";
import InvisibleQuality from "../components/invisible-qulaity";
import TeamDynamics from "../components/team-dynamics";

const OurPointOfView:FunctionComponent = ()=> {
    return( <div className="mt-14">
    <EngineeringPuzzle/>
    <HiddenChallanges/>
    <InvisibleQuality/>
    <TeamDynamics/>
    <BeyondEngineering/>
</div>)
}

export default OurPointOfView;