import React, { FunctionComponent } from "react";
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text'
import Section from './shared/section/sectionWrapper'
const BeyondEngineering: FunctionComponent = () => {
    return (
        <Section className="bg-bytestrone-blue px-4">
            <div id="beyond-engineering" className="pb-4"></div>
            <div id="beyond-engineering" className="flex flex-col w-full h-full p-2 py-8 space-y-5">
                <Text className="text-center text-white lg:text-right md:whitespace-nowrap" textType="headerL">Beyond Software Engineering</Text>

                <div>
                    <Text textType="contentXl" className="text-white">
                        There are many forces beyond software engineering that influence the outcome of
                        software delivery.
                    </Text>
                </div>

                <div>
                    <Text textType="contentXl" className="text-white">
                        Having a strong Software Engineering process that complements overall delivery is
                        one of the critical success factors.
                    </Text>
                </div>

                <div className="flex flex-wrap h-full md:pl-4 text-white">

                    <div className="flex w-full justify-center items-center">
                        <div className="hidden lg:block w-4/5">
                            <StaticImage alt="beyound-engineering" placeholder="blurred" quality={100}
                                src="../images/beyond-software-engineering.png" formats={["auto", "webp", "avif"]} />
                        </div>

                        <div className="lg:hidden">
                            <StaticImage alt="beyound-engineering" placeholder="blurred" quality={100}
                                src="../images/beyond-software-engineering-mobile.png" formats={["auto", "webp", "avif"]} />
                        </div>
                    </div>
                </div>
                <div className="pl-16">
                    <Text textType="content" className="text-white">
                        1. DoR - Definition of Ready
                    </Text>
                </div>
            </div>
        </Section>
    )
}

export default BeyondEngineering;